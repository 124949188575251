// 版本号
export const version = "V1.0.0";
// APP NAME
export const appName = "store";
// imei
export const imei = "gudinzhi";
export const os = "h5";
// 分发器基础地址
let baseURL = null;
/**
 * 修改运行环境
 */
let isDev = false; // 正式上线
// let isDev = true		// 开发或者测试中

let port = true; // 运营环境
// let port = false		// 测试环境
if (!port) {
  // 开发环境
  baseURL = "/";
  // baseURL = 'http://192.168.20.90:8093/'
  baseURL = "https://shopmt.loovee.com:1443";
} else {
  // 运营环境
  // baseURL = '/'
  // baseURL = 'http://shopmt.loovee.com:1443'
  //   baseURL = "http://shopm.loovee.com";
  // baseURL = 'http://wwjm.loovee.com/shop'
  baseURL = "/";
}

/** 兼容 Object.values  */
if (!Object.values)
  Object.values = function (obj) {
    if (obj !== Object(obj)) return "";

    var val = [],
      key;
    for (key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        val.push(obj[key]);
      }
    }
    return val;
  };

export { isDev, baseURL, port };

/* 网络资源图片前缀 */
export const imgUrl = "https://duimianimg.loovee.com/style/img/h5/store/";
// export const qiniuUrl = 'http://imgchaosale.loovee.com/'
export const qiniuUrl = "https://duimianimg.loovee.com/";

export const couponType = ["满减卷"];

export const appLink = {
  "app://jump_dollpage": "home",
  "app://myWallet": "recharge",
  "app://mydolls": "dolls",
  "app://invitePage": "invite",
  "app://myVipPage": "member",
  "app://couponPage": "coupon",
  "app://rankList": "rank",
  "app://monthPage": "weekMonCard",
  "app://taskCenter": "taskcenter",
  "app://dollRoomPage": "list",
};
